<template>
  <modal
    :modalId="`modalRemove`"
    :modalTitle="'Remover Banner'"
    :load="false"
    :typeLg="`modal_md`"
    @closeModal="closeModal"
  >
    <div class="col-md-12 mb-1 form-group form-outline">
      <label class="label_default label_blue label_sm"
        >Deseja realmente excluir o Banner
        <b class="b_danger"> {{ param.title }} </b>?
      </label>
      <actions
        :nameButton="'Excluir'"
        :disabled="disabled"
        @closeModal="closeModal"
        @submitform="submitform"
      />
    </div>
  </modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () => import("../acoes/botoes_rodape.vue")
  },
  props: ["disabled", "param"],
  methods: {
    ...mapActions(["RemoveNotice"]),
    submitform() {
      this.$emit("update:disabled", true);
      this.RemoveNotice(this.param.id)
        .then(res => {
          this.toast(res.msg, this.$toast.success);
          this.$emit("update:disabled", false);
          this.closeModal();
        })
        .catch(err => {
          this.toast(err.msg, this.$toast.error);
          this.$emit("update:disabled", false);
        });
    },

    closeModal() {
      this.$emit("closeModal", "modalRemove");
    },
    teste() {
      console.log("chamou");
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.b_danger {
  color: red;
}
</style>
